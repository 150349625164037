.paper {
  background: #fbfbf8;
  box-shadow:
    /* The top layer shadow */
    0 -1px 1px rgba(0,0,0,0.15),
    /* The second layer */
    20px -20px 0 -5px #fbfbf8,
    /* The second layer shadow */
    20px -20px 1px -4px rgba(0,0,0,0.15),
     /* The third layer */
    40px -40px 0 -10px #fbfbf8,
    /* The third layer shadow */
    40px -40px 1px -9px rgba(0,0,0,0.15);
    /* Padding for demo purposes */
  padding: 30px;
  margin: 50px 50px 30px 30px;
  border: 1px solid lightgray;
}