body {
  margin: 10;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}


input {
  padding: 12px 20px;
  margin: 8px;
  box-sizing: border-box;
  font-size: 16px;
}

.home {
  text-align: center;

  h1 {
    font-size: 80px;
    font-weight: bolder;
    margin: 10px;
  }

  input {
    padding: 12px 20px;
    margin: 5px;
    box-sizing: border-box;
    font-size: 40px;
  }

  .hint {
    margin-top: 1px;
    margin-bottom: 15px;
  }

  button {
    font-size: 30px;
  }
}